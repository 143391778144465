import styled from 'styled-components';
import React from 'react';
import WaffleText from '../../../Components/WaffleText';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Header = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #ffe8c6;
`;

type Props = {
  children: React.ReactNode;
};
export default function LifeContentContainer({
  children,
}: Props): React.ReactElement {
  return (
    <Container>
      <Header>
        <WaffleText
          style={{ fontSize: 24, textAlign: 'center', color: '#090A0A' }}>
          {'Your policy details'}
        </WaffleText>
      </Header>
      {children}
    </Container>
  );
}
