import { StackScreenProps } from '@react-navigation/stack';
import {
  CreateLoginScreenName,
  HomeScreenName,
  LifeApplicationScreenName,
  RootStackParamList,
} from '../../../screen-config';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SafeSecureCheckout } from '../PaymentInfoScreen/SafeSecureCheckout';
import { WaffleButtonAsync } from '../../Components/WaffleButtonAsync';
import { Spacer } from '../../Components/SimpleComponents';
import {
  CreditCardElement,
  LadderAPI,
} from '../LifeQuoteScreen/models/ladder-api-lib';
import {
  acceptOffer,
  getOffer,
  getUserToken,
  Offer,
} from '../LifeQuoteScreen/models/application';
import WaffleText, { WaffleTextFamily } from '../../Components/WaffleText';
import { useWaffleLogoNavigationHeader } from '../../useWaffleLogoNavigationHeader';
import { ProductLife } from '../../Constants/Products';
import LifeDisclosure from '../../Components/LifeDisclosure';
import { UpdateUserStateOnServer } from '../../Helper/UserHelpers';
import { USER_STATE_COMPLETE } from '../../Helper/NavigationService';
import { useAuthContext } from '../AuthProvider';
import { useApolloClient, useMutation } from '@apollo/client';
import {
  FinalizeUserPolicies2,
  FinalizeUserPolicies2Variables,
} from '../../../../operation-result-types';
import { FINALIZE_DRAFT_USER_POLICIES } from '../../GraphQL/Waffle/Queries';
import { getLifePolicyMetaDataAsync } from '../../../lib/life';
import PaymentGuarantee from '../PaymentInfoScreen/PaymentGuarantee';
import { WaffleOrange } from '../../Constants/Style';
import ContentLoader from 'react-content-loader';
import { useUserTracking } from '../../../lib/user-tracking/UserTrackingProvider';
import {
  errMsg,
  httpOfferErrMsg,
  paymentAppErrMsg,
} from '../LifeQuoteQuestionsScreen/utils/api-error-message';
import { ErrorContainer } from '../../Components/ErrorContainer';
import Sentry from '../../Sentry';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow-y: scroll;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 375px;
  padding: 16px;
`;

const CoverageContainer = styled.div`
  background: #fff6ea;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 100%;
  padding: 20px;
`;

const PaymentContainer = styled.div`
  height: 50px;
  width: 100%;
`;

const OrangeBar = styled.div`
  top: -15px;
  width: 100%;
  height: 2px;
  background: ${WaffleOrange};
  position: relative;
`;

let ladderApiSdk: LadderAPI;
let creditCardElement: CreditCardElement | null;

const PAYMENT_CONTAINER_ID = 'payment-container';

const CoverageNote = () => {
  const pretext =
    '*Your coverage will start once payment is made. The charge on your statement will be from "';
  const suffix = '" our partner in providing this term coverage.';

  return (
    <WaffleText
      style={{
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: '#1A1A1A',
        fontSize: 14,
        fontWeight: 'normal',
      }}>
      {pretext}
      <b>{'LADDER LIFE'}</b>
      {suffix}
    </WaffleText>
  );
};

function LoadingCreditCard() {
  return (
    <ContentLoader
      width={375}
      height={50}
      viewBox="0 0 375 45"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb">
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="26" />
    </ContentLoader>
  );
}

function defaultPriceState() {
  return {
    isLoading: true,
    pageLoadErr: '',
    submissionErr: '',
    value: '',
    offerId: '',
    lifeEmail: '',
  };
}

export default function LifeBillingScreen({
  navigation,
}: StackScreenProps<RootStackParamList, 'Life Billing'>): React.ReactElement {
  const [priceState, setPriceState] = useState(defaultPriceState());

  const apolloClient = useApolloClient();
  const userTracking = useUserTracking();

  /*
   * Assumes this happens only on page load since it sets defaultPriceState without needing to list the priceState as a hook dependency
   */
  async function tryGetOffer(offerID: string): Promise<Offer | null> {
    try {
      const offer = await getOffer(offerID);

      if (!offer) {
        throw new Error(`Offer was not found`);
      }

      return offer;
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);

      setPriceState({
        ...defaultPriceState(),
        isLoading: false,
        pageLoadErr: e.response
          ? httpOfferErrMsg(e.response.status)
          : errMsg(e.message),
      });
      return null;
    }
  }

  /*
   * Assumes this happens only on async submission and not on page load.
   */
  async function tryAcceptOffer(offerID: string): Promise<Offer | null> {
    try {
      const acceptedOffer = await acceptOffer(offerID);

      if (!acceptedOffer) {
        throw new Error(`Accepted offer was not found`);
      }

      return acceptedOffer;
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);

      const addtlErrDetails = e.response?.data
        ? ` \n\nAdditional Details\n${paymentAppErrMsg(e.response.data)}`
        : '';

      const detailedErrMsg = e.response
        ? `${httpOfferErrMsg(e.response.status)}.${addtlErrDetails}`
        : errMsg(e.message);

      setPriceState({
        ...priceState,
        isLoading: false,
        submissionErr: detailedErrMsg,
      });
      return null;
    }
  }

  const [finalizeUserPolicies] = useMutation<
    FinalizeUserPolicies2,
    FinalizeUserPolicies2Variables
  >(FINALIZE_DRAFT_USER_POLICIES);
  const { isGuest } = useAuthContext();

  // TODO: we should use useFocusEffect, but in RootScreen.tsx the transition purchase complete screen
  //  after isGuest is changed breaks this screen
  useEffect(() => {
    (async () => {
      try {
        const {
          ExternalUserId: userId,
          OfferId: offerId,
          ExternalEmail: lifeEmail,
        } = await getLifePolicyMetaDataAsync(apolloClient);
        const userToken = await getUserToken(userId);
        const offer = await tryGetOffer(offerId);

        if (!offer) {
          return;
        }
        const price = offer.monthly_premium_cents / 100;

        ladderApiSdk = new globalThis.LadderAPI(userToken);
        const paymentOperations = ladderApiSdk.payment(userId);
        creditCardElement = paymentOperations.createCreditCardElement({
          style: {
            base: {
              iconColor: '#F98D46',
              fontWeight: '400',
              fontFamily: WaffleTextFamily,
              fontSize: '18px',
              fontSmoothing: 'antialiased',
            },
          },
        });
        creditCardElement.mount(`#${PAYMENT_CONTAINER_ID}`);

        setPriceState({
          ...priceState,
          isLoading: false,
          value: price.toFixed(2),
          lifeEmail,
          offerId,
          pageLoadErr: '',
          submissionErr: '',
        });
      } catch (e) {
        setPriceState({
          ...defaultPriceState(),
          isLoading: false,
          pageLoadErr: e.message,
        });
      }
    })();

    return function cleanUp() {
      creditCardElement?.unmount();
    };
  }, []);

  useWaffleLogoNavigationHeader(navigation, LifeApplicationScreenName);

  async function onSubmitPayment() {
    try {
      if (priceState.submissionErr) {
        setPriceState({ ...priceState, submissionErr: '' });
      }

      await creditCardElement?.submit();

      const acceptedOffer = await tryAcceptOffer(priceState.offerId);

      if (!acceptedOffer) {
        return;
      }

      const finalizeUserPolicies2FetchResult = await finalizeUserPolicies({
        variables: { paymentMethodId: '' },
      });
      const [lifePolicy] =
        finalizeUserPolicies2FetchResult.data?.finalizeDraftUserPolicies
          ?.responses || [];
      await UpdateUserStateOnServer(apolloClient, USER_STATE_COMPLETE);

      await userTracking.publishLifeOrderCompleted(
        lifePolicy,
        Number.parseFloat(priceState.value)
      );

      if (isGuest) {
        navigation.navigate(CreateLoginScreenName, {
          initialEmail: priceState.lifeEmail,
          products: [ProductLife],
        });
      } else {
        navigation.navigate(HomeScreenName);
      }
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);

      setPriceState({
        ...priceState,
        isLoading: false,
        submissionErr: e.message,
      });
    }
  }

  if (priceState.pageLoadErr) {
    return (
      <Container>
        <Content>
          <ErrorContainer>{`An error occurred while loading the billing details page: ${priceState.pageLoadErr}\n\nPlease reload the page and try again.`}</ErrorContainer>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        <SafeSecureCheckout
          isLoading={priceState.isLoading}
          paymentFrequency={'/month'}
          price={priceState.value}
          selectedProductId={'Life'}
        />
        <Spacer y={6} />
        <PaymentContainer id={PAYMENT_CONTAINER_ID}>
          {priceState.isLoading ? <LoadingCreditCard /> : null}
        </PaymentContainer>
        <OrangeBar />
        <Spacer y={2} />
        <WaffleButtonAsync onPress={onSubmitPayment} name="Submit Payment" />
        <Spacer y={3} />
        {priceState.submissionErr && (
          <ErrorContainer>{priceState.submissionErr}</ErrorContainer>
        )}
        <PaymentGuarantee />
        <Spacer y={5} />
        <CoverageContainer>
          <CoverageNote />
        </CoverageContainer>
      </Content>
      <Spacer y={2} />
      <LifeDisclosure />
    </Container>
  );
}
