import { StackScreenProps } from '@react-navigation/stack';
import {
  LifeApplicationReviewScreenName,
  LifeApplicationScreenName,
  LifeApplicationDeclined,
  LifeRedirectScreenName,
  RootStackParamList,
} from '../../../screen-config';
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  answerQuestion,
  ApplicationResponse,
  autocomplete,
  Question,
  getUserToken,
  AnswerValue,
  getApplication,
  createApplication,
} from '../LifeQuoteScreen/models/application';
import { ErrorBoundary } from '../LifeQuoteScreen/ErrorBoundary';
import QuestionFormDynamic from '../LifeQuoteScreen/QuestionFormDynamic';
import { QuestionExternalDependencies } from '../LifeQuoteScreen/utils/ladder-life-coverage.constants';
import {
  ApplicationOperations,
  LadderAPI,
} from '../LifeQuoteScreen/models/ladder-api-lib';
import { useLadderLogoNavigationHeader } from '../LifeApplicationReviewScreen/useLadderNavigationHeader';
import {
  getLifePolicyMetaDataAsync,
  useLifeInsurance,
} from '../../../lib/life';
import { Spacer } from '../../Components/SimpleComponents';
import { useFocusEffect } from '@react-navigation/native';
import LifeProgressBar from '../LifeQuoteQuestionsScreen/LifeProgressBar';
import LifeDisclosure from '../../Components/LifeDisclosure';
import { useApolloClient } from '@apollo/client';
import { useUserTracking } from '../../../lib/user-tracking/UserTrackingProvider';
import { FAB } from 'react-native-elements';
import { WaffleOrange } from '../../Constants/Style';
import Popup from '../../Components/Popup';
import WaffleText from '../../Components/WaffleText';
import LifeApplicationContent from './components/LifeApplicationContent';
import LifeQuestionLoadingView from './LifeQuestionLoadingView';
import { useAuthContext } from '../AuthProvider';
import LifeContainer from '../../Helper/LifeContainer';

const ProgressBarContainer = styled.div`
  width: 100%;
  z-index: 2;
`;
const appContentZIndex = 1;
/**
 * Notes:
 * z-index increase allows elements like react-select that can't supersede their parent's z-index to not get overlapped
 * at the default 0 z-index value within the ApplicationContent component
 * */

type LocalState = {
  prevNextIncompleteQuestionId?: string;
  isInReview: boolean;
  isLoading: boolean;
  ladderAPI: LadderAPI;
  questions: Array<Question>;
  estimatedProgress: number;
  page: number;
  applicationId: string;
};

const mergeNextQuestion = (
  currentPage: number,
  questions: Question[],
  nextIncompleteQuestion: Question
) => {
  const questionCopy = questions.map((q) => ({ ...q }));
  const nextPage = currentPage + 1;
  questionCopy.splice(nextPage, 0, nextIncompleteQuestion);
  return questionCopy;
};

export default function LifeApplicationScreen({
  navigation,
  route,
}: StackScreenProps<
  RootStackParamList,
  'Life Application'
>): React.ReactElement {
  const apolloClient = useApolloClient();
  const { isGuest } = useAuthContext();
  const { setRedirectReason } = useLifeInsurance();
  const initialPage = route.params?.initialPage
    ? parseInt(route.params?.initialPage, 10)
    : undefined;
  const userTracking = useUserTracking();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const [applicationState, setApplicationState] = useState<LocalState>({
    isInReview: false,
    isLoading: true,
    ladderAPI: null,
    questions: [],
    estimatedProgress: 0,
    page: 0,
    applicationId: null,
  });

  useFocusEffect(
    React.useCallback(() => {
      (async () => {
        setApplicationState({
          ...applicationState,
          isLoading: true,
        });
        const {
          ExternalQuoteId: quoteId,
          ExternalEmail: lifeEmail,
          ExternalApplicationId: _applicationId,
        } = await getLifePolicyMetaDataAsync(apolloClient);

        let application: ApplicationResponse;
        if (_applicationId) {
          application = await getApplication(_applicationId);
        } else {
          application = await createApplication({
            quoter: quoteId,
            email: lifeEmail,
          });
        }

        const userToken = await getUserToken(application.user);

        if (!application.next_incomplete_question && !initialPage) {
          navigation.navigate(LifeApplicationReviewScreenName);
          return;
        }

        const _questions = mergeNextQuestion(
          initialPage ?? application.answered_questions.length - 1,
          application.answered_questions,
          application.next_incomplete_question
        );

        const currentPage = initialPage ?? _questions.length - 1;

        if (_questions[currentPage]?.id === 'ApplicationApiFraudLanguage') {
          navigation.navigate(LifeApplicationReviewScreenName);
          return;
        }

        setApplicationState({
          ...applicationState,
          questions: _questions,
          page: currentPage,
          prevNextIncompleteQuestionId:
            application.next_incomplete_question?.id,
          isLoading: false,
          estimatedProgress: application.estimated_progress,
          ladderAPI: new globalThis.LadderAPI(userToken) as LadderAPI,
          applicationId: application.id,
        });
      })();
    }, [initialPage])
  );

  useLadderLogoNavigationHeader(
    navigation,
    LifeApplicationScreenName,
    apolloClient,
    isGuest
  );

  function _redirectUser(reason: string | null) {
    if (reason === 'ineligible_for_api') {
      setRedirectReason(
        'Because of your answers we cannot directly handle your life insurance request.'
      );
    } else {
      setRedirectReason(reason?.replaceAll('_', ' '));
    }

    navigation.navigate(LifeRedirectScreenName);
  }

  async function onNextQuestion(questionID: string, answer: AnswerValue) {
    document.getElementById('app-question-screen-container').scrollTop = 0;
    const response = await answerQuestion(applicationState.applicationId, {
      id: questionID,
      value: answer,
    });

    userTracking?.publishLifeInsuranceAppQuestionAnswered(
      response.estimated_progress
    );

    if (response.eligible_for_api === false) {
      setRedirectReason(response.ineligibility_reason);
      navigation.navigate(LifeRedirectScreenName);
      return;
    }

    if (response.ineligible) {
      navigation.navigate(LifeApplicationDeclined);
      return;
    }

    if (
      !response.next_incomplete_question ||
      response.next_incomplete_question?.id === 'ApplicationApiFraudLanguage'
    ) {
      navigation.navigate(LifeApplicationReviewScreenName);
      return;
    }

    let questions = applicationState.questions;
    const hasNewIncompleteQuestion =
      response.next_incomplete_question &&
      applicationState.prevNextIncompleteQuestionId !==
        response.next_incomplete_question?.id;

    let nextPage;
    if (hasNewIncompleteQuestion) {
      questions = mergeNextQuestion(
        applicationState.page,
        response.answered_questions,
        response.next_incomplete_question
      );
      nextPage = applicationState.page + 1;
    } else {
      nextPage = questions.findIndex(
        (p) => p.id === response.next_incomplete_question.id
      );
    }

    setApplicationState({
      ...applicationState,
      questions,
      isLoading: false,
      page: nextPage,
      estimatedProgress: response.estimated_progress,
      prevNextIncompleteQuestionId: response.next_incomplete_question?.id,
      applicationId: response.id,
    });
  }

  async function onPreviousQuestion(questionID: string, answer: AnswerValue) {
    if (!answer || (Array.isArray(answer) && answer.length === 0)) {
      const previousPage = applicationState.page - 1;

      setApplicationState({
        ...applicationState,
        page: previousPage < 0 ? 0 : previousPage,
      });
      return;
    }

    const response = await answerQuestion(applicationState.applicationId, {
      id: questionID,
      value: answer,
    });

    if (response.eligible_for_api === false) {
      setRedirectReason(response.ineligibility_reason);
      navigation.navigate(LifeRedirectScreenName);
      return;
    }

    if (response.ineligible) {
      navigation.navigate(LifeApplicationDeclined);
      return;
    }

    let questions = applicationState.questions;
    const hasNewIncompleteQuestion =
      response.next_incomplete_question &&
      applicationState.prevNextIncompleteQuestionId !==
        response.next_incomplete_question?.id;

    if (hasNewIncompleteQuestion) {
      questions = mergeNextQuestion(
        applicationState.page,
        response.answered_questions,
        response.next_incomplete_question
      );
    }

    const previousPage = applicationState.page - 1;

    setApplicationState({
      ...applicationState,
      questions,
      isLoading: false,
      page: previousPage < 0 ? 0 : previousPage,
      estimatedProgress: response.estimated_progress,
      prevNextIncompleteQuestionId: response.next_incomplete_question?.id,
      applicationId: response.id,
    });
  }

  return (
    <LifeContainer id="app-question-screen-container">
      <ProgressBarContainer>
        <LifeProgressBar
          percent={Math.trunc(applicationState.estimatedProgress * 100)}
        />
      </ProgressBarContainer>
      {applicationState.isLoading ? (
        <>
          <Spacer y={4} />
          <LifeQuestionLoadingView />
          <Spacer y={4} />
        </>
      ) : (
        <>
          <Spacer y={4} />
          <ErrorBoundary>
            <LifeApplicationContent style={{ zIndex: appContentZIndex }}>
              <QuestionFormDynamic
                formStyle={{
                  maxWidth: '100%',
                }}
                onSubmit={onNextQuestion}
                onPrevious={onPreviousQuestion}
                externalDependencies={
                  {
                    getAutoCompleteResults: autocomplete,
                    getLadderAppOperations: (): ApplicationOperations =>
                      applicationState.ladderAPI.application(
                        applicationState.applicationId
                      ),
                  } as QuestionExternalDependencies
                }
                initialFormData={{}}
                formQuestion={applicationState.questions[applicationState.page]}
              />
            </LifeApplicationContent>
          </ErrorBoundary>
          <Spacer y={4} />
        </>
      )}
      <LifeDisclosure />
      <Spacer y={8} />

      <FAB
        style={{ zIndex: appContentZIndex }}
        color={WaffleOrange}
        onPress={() => setModalIsOpen(true)}
        visible={true}
        placement="right"
        icon={{ name: 'question', color: 'white', type: 'material-community' }}
      />
      <Popup
        hasSideMenu={false}
        visible={modalIsOpen}
        onDismiss={() => setModalIsOpen(false)}>
        <Spacer y={2} />
        <WaffleText style={{ textAlign: 'center', fontSize: 18 }}>
          {'Have a question or running into any issues? Email us at '}
          <a
            style={{
              color: WaffleOrange,
            }}
            href="mailto:contact@waffle-labs.com?subject=Waffle%20Life%20Insurance%20Support"
            target="_blank"
            rel="noreferrer">
            {'contact@waffle-labs.com'}
          </a>
        </WaffleText>
      </Popup>
    </LifeContainer>
  );
}
