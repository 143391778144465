import styled from 'styled-components';

const LifeApplicationContent = styled.div`
  box-shadow: 0px 4px 28px -9px rgba(0, 0, 0, 0.25);
  display: flex;
  background: white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
  width: 324px;
  padding: 16px;
`;

export default LifeApplicationContent;
