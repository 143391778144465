import { StackScreenProps } from '@react-navigation/stack';
import {
  LifeBillingScreenName,
  LifeOfferScreenName,
  RootStackParamList,
} from '../../../screen-config';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import styledNative from 'styled-components/native';
import WaffleText from '../../Components/WaffleText';
import { Spacer } from '../../Components/SimpleComponents';
import { WaffleButtonAsync } from '../../Components/WaffleButtonAsync';
import { getOffer, Offer } from '../LifeQuoteScreen/models/application';
import format from 'date-fns/format';
import { useLadderLogoNavigationHeader } from '../LifeApplicationReviewScreen/useLadderNavigationHeader';
import { getLifePolicyMetaDataAsync } from '../../../lib/life';
import { useApolloClient } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/native';
import LifeDisclosure from '../../Components/LifeDisclosure';
import LifeContentContainer from './components/LifeContentContainer';
import LoadingRectangle from '../../Components/LoadingRectangle';
import {
  errMsg,
  httpOfferErrMsg,
} from '../LifeQuoteQuestionsScreen/utils/api-error-message';
import { ErrorContainer } from '../../Components/ErrorContainer';
import Sentry from '../../Sentry';
import { useAuthContext } from '../AuthProvider';
import LifeContainer from '../../Helper/LifeContainer';

const Card = styled.div`
  display: flex;
  background: white;
  flex-direction: column;
  border-radius: 20px;
  max-width: 384px;
  justify-content: flex-start;
  padding: 16px;
`;

const ImageContainer = styledNative.Image`
  width: 100%;
  height: 260px;
`;

const PolicyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 21px;
  box-shadow: 0px 4px 28px -9px rgba(0, 0, 0, 0.25);
`;

const PolicyContainerHeader = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #ffe8c6;
  box-shadow: 0px 4px 28px -9px rgba(0, 0, 0, 0.25);
  border-radius: 21px 21px 0px 0px;
`;

type AppState = {
  coverageAmount: string;
  term: string;
  monthlyPrice: string;
  offerExpiration: string;
  isLoading: boolean;
  error: string;
};

const defaultAppState = (): AppState => {
  return {
    coverageAmount: null,
    term: null,
    monthlyPrice: null,
    offerExpiration: null,
    isLoading: true,
    error: '',
  };
};

const mapOfferToViewModel = (
  offer: Offer
): Omit<AppState, 'isLoading' | 'error'> => {
  const coverageAmount = offer.face_amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const term = `${offer.duration_years} years`;
  const monthlyPrice = (offer.monthly_premium_cents / 100).toLocaleString(
    'en-US',
    {
      style: 'currency',
      currency: 'USD',
    }
  );

  const offerExpiration = format(offer.expires_at * 1000, 'MMMM d, yyyy');

  return {
    coverageAmount,
    term,
    monthlyPrice,
    offerExpiration,
  };
};
const messageContent =
  'Now, activate your policy and add a payment method. Your coverage will start once payment is made. As always, reach out with questions. We’re here to help, not sell.';

const ScreenImages = {
  person: require('../LifeApplicationReviewScreen/assets/offer-image.png'),
};

export default function LifeOfferScreen({
  navigation,
}: StackScreenProps<RootStackParamList, 'Life Offer'>): React.ReactElement {
  const [state, setState] = useState<AppState>(defaultAppState());
  const { isGuest } = useAuthContext();
  const apolloClient = useApolloClient();

  useLadderLogoNavigationHeader(
    navigation,
    LifeOfferScreenName,
    apolloClient,
    isGuest
  );

  async function onSubmit() {
    navigation.navigate(LifeBillingScreenName);
  }

  /*
   * Assumes this happens only on page load since it sets defaultAppState without needing to list the state as a hook dependency
   */
  async function tryGetOffer(offerID: string): Promise<Offer | null> {
    try {
      const offer = await getOffer(offerID);

      if (!offer) {
        throw new Error(`Offer was not found`);
      }

      return offer;
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      const error = e.response
        ? httpOfferErrMsg(e.response.status)
        : errMsg(e.message);

      setState({
        ...defaultAppState(),
        error,
        isLoading: false,
      });
      return null;
    }
  }

  useFocusEffect(
    useCallback(() => {
      (async () => {
        try {
          const lifePolicyMetaData = await getLifePolicyMetaDataAsync(
            apolloClient
          );
          const offer = await tryGetOffer(lifePolicyMetaData.OfferId);

          if (!offer) {
            return;
          }

          setState({
            ...mapOfferToViewModel(offer),
            isLoading: false,
            error: '',
          });
        } catch (e) {
          console.error(e);
          Sentry.captureException(e);

          setState({
            ...defaultAppState(),
            error: e.message,
            isLoading: false,
          });
        }
      })();
    }, [])
  );

  if (state.error) {
    return (
      <LifeContainer>
        <Card>
          <ErrorContainer>{`An error occurred while retrieving your offer: ${state.error}\n\nPlease reload the page and try again.`}</ErrorContainer>
        </Card>
      </LifeContainer>
    );
  }

  return (
    <LifeContainer>
      <Spacer y={4} />
      <Card>
        <WaffleText style={{ fontSize: 55, textAlign: 'center' }}>
          {'You’re approved!'}
        </WaffleText>
        <Spacer y={4} />
        <ImageContainer source={ScreenImages.person} />
        <Spacer y={4} />
        <WaffleText style={{ fontSize: 15, textAlign: 'center' }}>
          {messageContent}
        </WaffleText>
        <Spacer y={4} />
        <LifeContentContainer>
          <Spacer y={4} />
          <WaffleText
            style={{ fontSize: 15, textAlign: 'center', color: '#5D5D5D' }}>
            {'Coverage Amount'}
          </WaffleText>
          {state.isLoading ? (
            <LoadingRectangle height={50} width={235} />
          ) : (
            <WaffleText
              style={{ fontSize: 40, textAlign: 'center', fontWeight: 'bold' }}>
              {state.coverageAmount}
            </WaffleText>
          )}

          <Spacer y={4} />

          <WaffleText
            style={{ fontSize: 15, textAlign: 'center', color: '#5D5D5D' }}>
            {'Term'}
          </WaffleText>
          {state.isLoading ? (
            <LoadingRectangle height={50} width={150} />
          ) : (
            <WaffleText
              style={{ fontSize: 40, textAlign: 'center', fontWeight: 'bold' }}>
              {state.term}
            </WaffleText>
          )}

          <Spacer y={4} />

          <WaffleText
            style={{ fontSize: 15, textAlign: 'center', color: '#5D5D5D' }}>
            {'Monthly price'}
          </WaffleText>
          {state.isLoading ? (
            <LoadingRectangle height={50} width={120} />
          ) : (
            <WaffleText
              style={{ fontSize: 40, textAlign: 'center', fontWeight: 'bold' }}>
              {state.monthlyPrice}
            </WaffleText>
          )}

          <Spacer y={6} />

          <WaffleButtonAsync
            disabled={state.isLoading}
            onPress={onSubmit}
            name="Activate my policy"
          />

          <Spacer y={4} />

          {state.isLoading ? (
            <LoadingRectangle height={50} width={120} />
          ) : (
            <WaffleText
              style={{ fontSize: 12, color: '#1A1A1A', textAlign: 'center' }}>
              {`Offer expires: ${state.offerExpiration}`}
            </WaffleText>
          )}
          <Spacer y={4} />
        </LifeContentContainer>
      </Card>
      <Spacer y={6} />
      <LifeDisclosure />
      <Spacer y={6} />
    </LifeContainer>
  );
}
