import ContentLoader from 'react-content-loader';
import { Spacer } from '../../Components/SimpleComponents';
import { ArrowButton } from '../LifeQuoteScreen/ArrowButton';
import React from 'react';
import LifeApplicationContent from './components/LifeApplicationContent';
import styled from 'styled-components';

const LifeApplicationLoadingFooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
`;

export default function LifeQuestionLoadingView(): React.ReactElement {
  return (
    <LifeApplicationContent>
      <ContentLoader
        speed={2}
        width="100%"
        height={160}
        viewBox="0 0 400 160"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb">
        <rect x="0" y="5" rx="0" ry="0" width="100%" height="45" />
        <rect x="100" y="90" rx="0" ry="0" width="50%" height="25" />
        <rect x="100" y="130" rx="0" ry="0" width="50%" height="25" />
      </ContentLoader>
      <Spacer y={2} />
      <LifeApplicationLoadingFooterContainer>
        <ArrowButton direction="left" disabled={true} />
        <Spacer x={4} />
        <ArrowButton
          onPress={() => {
            /** no-op **/
          }}
          disabled={true}
        />
      </LifeApplicationLoadingFooterContainer>
    </LifeApplicationContent>
  );
}
